import * as React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import './styles.scss'

export const DIALOG_MODE = {
  POPUP: 'popup',
  FULLSCREEN: 'fullscreen'
}

const titleStyles = () => ({
  root: {
    margin: 0,
    padding: 20,
    color: '#323232'
  }
})

const DialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children && <Typography variant="h5">{children}</Typography>}
    </MuiDialogTitle>
  )
})

DialogTitle.propTypes = {
  children: PropTypes.node
}

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    '&:first-child': {
      padding: 0
    }
  }
}))(props => {
  const { children, classes, ...other } = props
  return (
    <MuiDialogContent className={classes.root} {...other}>
      {children}
    </MuiDialogContent>
  )
})

const styles = theme => ({
  paper: {
    margin: 0,
    minWidth: '300px',
    borderRadius: '16px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '343px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '350px'
    }
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
    zIndex: 1
  }
})
function ProvenDialog({
  classes,
  mode,
  open,
  title,
  children,
  showCloseButton,
  onClose,
  customCloseIcon,
  sx
}) {
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      fullScreen={mode === DIALOG_MODE.FULLSCREEN}
      onClose={onClose}
      styleName="proven-dialog"
      PaperProps={{
        sx
      }}
    >
      {showCloseButton && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          {customCloseIcon ? customCloseIcon : <CloseIcon />}
        </IconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

ProvenDialog.defaultProps = {
  mode: DIALOG_MODE.POPUP
}

ProvenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  mode: PropTypes.oneOf([DIALOG_MODE.FULLSCREEN, DIALOG_MODE.POPUP]).isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
  customCloseIcon: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(ProvenDialog)
