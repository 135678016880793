import React from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { countryCodeSelect } from '../../../../utils/selectors'
import { Typography, Box, RadioGroup, FormControl } from '@mui/material'
import ProvenToggle from '../../../shared/proven-toggle'
import { createProvenTheme } from '../../../../styles/theme-proven'
import { formatPrice } from '../../../../utils/helpers'
import useCurrency from '../../../../hooks/useCurrency'
import { capitalize } from 'lodash'
import {
  DEFAULT_SUBSCRIPTION_PERIOD_UNIT,
  DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD
} from '../../../../constants/subscriptions'

const themeProven = createProvenTheme()
const spacing = themeProven.spacing

function SubscribeAndSaveSelector({
  sx,
  subscriptionPrice,
  oneTimePrice,
  isSubscriptionSelected,
  onSubscriptionSelectionChange,
  isAlreadyThereAnActiveSubscription
}) {
  const { currencyToDisplayOrEmptyIfUS, currencySymbol } = useCurrency()

  const countryCode = useSelector(countryCodeSelect)

  //TODO extract to a hook or helper
  const calcSubscriptionSavingPercentage = (subscriptionPrice, oneTimePrice) => {
    if (!subscriptionPrice || !oneTimePrice) {
      return 0
    }

    const percentage = (1 - subscriptionPrice / oneTimePrice) * 100

    if (percentage <= 0) {
      console.error('In calcSubscriptionSavingPercentage, percentage should be greater than 0')
      return 0
    }

    return Math.floor(percentage)
  }

  const renderLabel = ({ isSubscription, selected }) => {
    const subscriptionSavingPercentage = isSubscription
      ? calcSubscriptionSavingPercentage(subscriptionPrice, oneTimePrice)
      : 0

    const title = isSubscription
      ? isAlreadyThereAnActiveSubscription
        ? 'Add To Subscription'
        : subscriptionSavingPercentage
        ? `Subscribe & Save ${subscriptionSavingPercentage}%`
        : 'Subscribe'
      : isAlreadyThereAnActiveSubscription
      ? 'Purchase One Time'
      : 'One-time Purchase'
    const subTitle = isSubscription
      ? isAlreadyThereAnActiveSubscription
        ? 'Ships with your subscription'
        : `Ships Every ${DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD} ${capitalize(
            DEFAULT_SUBSCRIPTION_PERIOD_UNIT
          )}s. Cancel Anytime`
      : isAlreadyThereAnActiveSubscription
      ? 'Ship immediately'
      : ''

    const price = isSubscription
      ? `${currencySymbol}${formatPrice(subscriptionPrice)}${currencyToDisplayOrEmptyIfUS}`
      : `${currencySymbol}${formatPrice(oneTimePrice)}${currencyToDisplayOrEmptyIfUS}`

    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={2.75}>
        <Box display="flex" flexDirection="column" gap={isSubscription ? 1 : 0}>
          <Typography variant="body1" color={selected ? 'primary.main' : 'gray.elysian'}>
            {title}
          </Typography>
          <Typography variant="footnote" color={selected ? 'primary.main' : 'gray.blueGray'}>
            {subTitle}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color={selected ? 'primary.main' : 'gray.elysian'}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {price}
        </Typography>
      </Box>
    )
  }

  const renderBadge = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: theme => theme.spacing(5),
        px: 2,
        borderRadius: 1,
        position: 'absolute',
        top: theme => theme.spacing(-2.5),
        right: theme => theme.spacing(3)
      }}
      bgcolor="primary.main"
    >
      <Typography variant="body2" color="gray.white">
        Most Popular
      </Typography>
    </Box>
  )

  const value = isSubscriptionSelected ? 1 : 0

  const subscriptionProvenToggle = (
    <ProvenToggle
      label={renderLabel({ isSubscription: true, selected: isSubscriptionSelected })}
      name="subscription"
      value={1}
      selected={isSubscriptionSelected}
      sx={{
        mb: isAlreadyThereAnActiveSubscription ? 0 : 1,
        height: { xs: spacing(14.1125), md: spacing(12.625) }
      }}
    />
  )
  const oneTimeProvenToggle = (
    <ProvenToggle
      label={renderLabel({ isSubscription: false, selected: !isSubscriptionSelected })}
      name="one-time"
      value={0}
      sx={{
        mb: isAlreadyThereAnActiveSubscription ? 1 : 0,
        height: { xs: spacing(14.1125), md: spacing(12.625) }
      }}
      selected={!isSubscriptionSelected}
    />
  )

  if (countryCode && countryCode.toUpperCase() === 'US') {
    return (
      <Box sx={sx}>
        <FormControl sx={{ width: 1 }}>
          <RadioGroup
            aria-labelledby="skip-the-line-select"
            name="skip-the-line-select"
            value={value}
            onChange={onSubscriptionSelectionChange}
          >
            {isAlreadyThereAnActiveSubscription ? (
              <>
                {oneTimeProvenToggle}
                {subscriptionProvenToggle}
              </>
            ) : (
              <>
                {renderBadge()}
                {subscriptionProvenToggle}
                {oneTimeProvenToggle}
              </>
            )}
          </RadioGroup>
        </FormControl>
      </Box>
    )
  } else {
    return <></>
  }
}

SubscribeAndSaveSelector.propTypes = {
  sx: propTypes.object,
  subscriptionPrice: propTypes.number,
  oneTimePrice: propTypes.number,
  isSubscriptionSelected: propTypes.number,
  onSubscriptionSelectionChange: propTypes.func
}

export default SubscribeAndSaveSelector
